import * as React from 'react'
import { useHref } from 'react-router-dom'
import {
  HealthBenefitsDisplayIcon,
  HealthInsuranceDisplayIcon
} from '@toasttab/buffet-pui-icons'
import { PayrollDashboardCard } from '@toasttab/buffet-pui-payroll-dashboard-card'
import { t } from '@local/translations'
import { useCustomer, useUser } from '@local/contexts'
import { NotAuthorizedState } from '@local/not-authorized-state'
import { trackDashboard } from '@local/track'

export const Dashboard: React.FunctionComponent = () => {
  const hasPermission = useUser().isPureUserHRPlusOrAbove
  const hasPayrollPro = useCustomer().hasPayrollPro
  const hasBenefitsModule = useCustomer().hasBenefitsModule
  const hasVestwellRetirementPlanOption =
    useCustomer().hasVestwellRetirementPlanOption

  return !hasPermission ? (
    <NotAuthorizedState />
  ) : hasPayrollPro ? (
    <div className='grid md:grid-cols-2 gap-6 w-fit'>
      <HealthBenefitsCard />
      <DeductionsCard />
      {hasBenefitsModule && <LegacyBenefitsCard />}
      {hasVestwellRetirementPlanOption && <VestwellRetirementPlanCard />}
    </div>
  ) : (
    <>
      <>TODO Doesn't have payroll pro</> {/*todo pcard-2296*/}
      {hasVestwellRetirementPlanOption && <VestwellRetirementPlanCard />}
    </>
  )
}

const HealthBenefitsCard: React.FunctionComponent = () => {
  const href = useHref('group-health')
  return (
    <PayrollDashboardCard
      {...trackDashboard('group-health')}
      badgeLabel={t('dashboard.group-health.badge')}
      description={t('dashboard.group-health.description')}
      icon={<HealthInsuranceDisplayIcon accessibility='decorative' />}
      subtitle={t('dashboard.group-health.subtitle')}
      title={t('dashboard.group-health.title')}
      linkAction={{
        label: t('dashboard.group-health.link'),
        href: href
      }}
    />
  )
}

const DeductionsCard: React.FunctionComponent = () => {
  return (
    <PayrollDashboardCard
      {...trackDashboard('manual-deduction')}
      description={t('dashboard.deductions.description')}
      icon={<HealthBenefitsDisplayIcon accessibility='decorative' />}
      subtitle={t('dashboard.deductions.subtitle')}
      title={t('dashboard.deductions.title')}
      linkAction={{
        label: t('dashboard.deductions.link'),
        onClick: undefined // todo pcard-2295
      }}
    />
  )
}

const LegacyBenefitsCard: React.FunctionComponent = () => {
  const client = useUser().client
  const linkHref = useHref(`/mvc/${client}/Company/Benefits/Dashboard`)

  return (
    <PayrollDashboardCard
      {...trackDashboard('legacy')}
      description={t('dashboard.legacy-benefits.description')}
      subtitle={t('dashboard.legacy-benefits.subtitle')}
      title={t('dashboard.legacy-benefits.title')}
      primaryAction={{
        label: t('dashboard.legacy-benefits.primary-button')
      }}
      linkAction={{
        label: t('dashboard.legacy-benefits.link'),
        href: linkHref
      }}
    />
  )
}

const VestwellRetirementPlanCard: React.FunctionComponent = () => {
  return (
    <PayrollDashboardCard
      {...trackDashboard('vestwell')}
      description={t('dashboard.vestwell-retirement.description')}
      subtitle={t('dashboard.vestwell-retirement.subtitle')}
      title={t('dashboard.vestwell-retirement.title')}
      linkAction={{
        label: t('dashboard.vestwell-retirement.link'),
        href: 'https://go.vestwell.com/toast',
        newTab: true
      }}
    />
  )
}
